<template>
	<v-menu
		v-model="menu2"
		:close-on-content-click="false"
		:nudge-right="40"
		:disabled="disabled"
		transition="scale-transition"
		offset-y
		min-width="auto"
	>
		<template v-slot:activator="{ on, attrs }">
			<div v-on="on">
				<v-text-field
					:value="computedDateFormatted"
					:label="label"
					:disabled="disabled"
					append-icon="mdi-calendar"
					v-bind="attrs"
					hide-details
					readonly
					outlined
					dense
				></v-text-field>
			</div>
		</template>
		<v-date-picker
			v-model="date"
			no-title
			@input="menu2 = false"
			locale="es"
		></v-date-picker>
	</v-menu>
</template>
<script>
export default {
	name: 'CbodyDatePicker',
	props: {
		value: {
			type: String,
			required: false,
		},
		label: {
			type: String,
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
		},
	},
	data: () => ({
		menu2: false,
	}),
	computed: {
		date: {
			get() {
				return this.value || null;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		computedDateFormatted() {
			return this.formatDate(this.date);
		},
	},
	methods: {
		formatDate(date) {
			if (!date) return null;
			const [year, month, day] = date.split('-');
			return `${day}-${month}-${year}`;
		},
	},
};
</script>
