import { $EventBus } from '@/main';
import textTransform from '@/helpers/textTransform.js';

export default {
	name: 'EvaluationDialog',
	props: {
		value: {
			type: Boolean,
			required: true,
		},
		disabled: {
			type: Boolean,
			required: false,
		},
		kit: {
			type: Object,
			required: false,
		},
	},
	data: () => ({
		valid: null,
		tool: null,
		rules: [(v) => !!v || 'Campo requerido'],
		quantityRules: [
			(v) => !!v || 'Campo requerido',
			(v) => /^\d*$/.test(v) || 'Campo numérico',
			(v) => v > 0 || 'Campo numérico',
		],
		initialEvaluations: [],
	}),
	computed: {
		dialog: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	watch: {
		value(newVal) {
			if (newVal) {
				this.tool = this.kit;
				this.initialEvaluations = JSON.parse(
					JSON.stringify(this.kit.evaluations)
				);
			}
		},
	},
	methods: {
		textTransform,

		hasObservation() {
			const result = this.tool.evaluations.some((item) => {
				return item.value && item.value == 'MALO';
			});
			return result;
		},

		saveEvaluation() {
			const isCompletedForm = this.$refs.form.validate();
			if (isCompletedForm) {
				const data = {
					...this.tool,
					hasObservation: this.hasObservation(),
				};
				this.$emit('addExtinguishers', JSON.parse(JSON.stringify(data)));
				this.closeEvaluation();
			} else {
				$EventBus.$emit('showSnack', 'warning', 'Completar Evaluación');
			}
		},

		closeEvaluation() {
			this.$nextTick(() => {
				this.$refs.form.reset();
				this.tool = null;
				this.dialog = false;
			});
		},

		resetQuantity(val, index, item) {
			if (['NO TIENE', 'NO APLICA'].includes(val)) {
				item.quantity = 0;
			} else {
				item.quantity = this.initialEvaluations[index].quantity;
			}
		},
	},
	components: {},
};
