<template>
	<component v-bind:is="componentName" :input="params" v-if="findComponent"></component>
</template>
<script>
export default {
	name: 'InputGlobal',
	props: {
		params: Object,
	},
	data: () => ({
		findComponent: false,
		componentName: '',
	}),
	created() {
		this.setComponentName();
	},
	methods: {
		setComponentName() {
			const componentList = [
				{ componentName: 'c-text-field', inputType: 'text' },
				{ componentName: 'c-select', inputType: 'select' },
                { componentName: 'c-radio', inputType: 'radio' },
                { componentName: 'c-date-picker', inputType: 'date' },
                { componentName: 'c-time-picker', inputType: 'time' },
				{ componentName: 'c-autocomplete', inputType: 'autocomplete' },
			];
			const foundComponent = componentList.find((element) => element.inputType === this.params?.input_type);
			if (foundComponent) {
				this.componentName = foundComponent.componentName;
				this.findComponent = true;
			}
		},
	},
	components: {
		CSelect: () => import('@/components/inspections/inputs/CSelect.vue'),
        CRadio: () => import('@/components/inspections/inputs/CRadio.vue'),
        CTextField: () => import('@/components/inspections/inputs/CTextField.vue'),
        CDatePicker: () => import('@/components/inspections/inputs/CDatePicker.vue'),
        CTimePicker: () => import('@/components/inspections/inputs/CTimePicker.vue'),
		CAutocomplete: () => import('@/components/inspections/inputs/CAutocomplete.vue'),
	},
};
</script>
