var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pt-4 pb-8",attrs:{"elevation":0}},[_c('v-card-text',[(!_vm.disabled)?_c('v-btn',{staticClass:"mb-4",attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.$emit('openDialog')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Kit ")],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var index = ref.index;
return [_vm._v(" Estado Kit ("+_vm._s(index + 1)+") ")]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.descriptions[0].value)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"right":""}},[_vm._v(" "+_vm._s(item.hasObservation ? 'mdi-alert-circle-outline' : 'mdi-check-circle-outline')+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.disabled)?_c('v-icon',{staticClass:"ma-1",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-eye ")]):[_c('v-icon',{staticClass:"ma-1",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"ma-1",on:{"click":function($event){return _vm.removeItem(item.currentId)}}},[_vm._v(" mdi-delete ")])]]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }