import { mapActions, mapState } from 'vuex';
import Swal from 'sweetalert2';
import { $EventBus } from '@/main';
import FormHeader from '@/components/inspections/formats/format-a/headers/FormHeader.vue';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import textTransform from '@/helpers/textTransform.js';
import ElementTable from '@/components/inspections/formats/hseq-gam1-f-22/bodies/ElementTable/ElementTable.vue';
import EvaluationDialog from '@/components/inspections/formats/hseq-gam1-f-22/bodies/EvaluationToolDialog/EvaluationDialog.vue';

export default {
	name: 'RegistersShowHseqGam1F22',
	data: () => ({
		steps: {
			current: 1,
			views: [1],
		},
		validationErrors: [],
		toolDialog: false,
		evaluationToolDialog: false,
		isLoadingData: false,
		isLoadingSave: false,
		isLoadingDraft: false,

		items: [],
		currentItem: null,
	}),
	computed: {
		...mapState('inspection', ['inspection']),
		...mapState('register', ['register']),
		...mapState('security', ['costCenters']),

		breadcrumbs: function () {
			return [
				{
					text: 'Inspecciones',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'InspectionsList',
					},
				},
				{
					text: this.inspection
						? textTransform(this.inspection.name)
						: this.$route.params.inspectionId,
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'InspectionsRegisterList',
						params: { id: this.$route.params.inspectionId },
					},
				},
				{
					text: 'Nuevo registro',
					disabled: true,
					href: '/',
				},
			];
		},
		isCompletedInspectons: function () {
			return this.isCompleteInspection();
		},
	},
	created() {
		this.initialize();
	},
	methods: {
		...mapActions('inspection', ['find', 'cleanInspection']),
		...mapActions('register', ['create', 'findById', 'updateStatus']),

		textTransform,

		initialize() {
			$EventBus.$emit('showSubNav', false);
			this.isLoadingData = true;
			this.find({
				success: () => {
					this.getRegister();
				},
				fail: () => {
					this.isLoadingData = false;
				},
				id: this.$route.params.inspectionId,
			});
		},
		getRegister() {
			this.findById({
				success: () => {
					this.setDataInspection();
					this.isLoadingData = false;
				},
				fail: () => {
					this.isLoadingData = false;
				},
				params: {
					inspectionId: this.$route.params?.inspectionId,
					registerId: this.$route.params?.id,
				},
			});
		},
		setDataInspection() {
			const headers = this.register?.registerHeaders || null;
			const items = this.register?.ambientalKits || [];

			if (Array.isArray(headers)) {
				headers.forEach((header) => {
					this.$store.dispatch('inspection/updateInputHeader', {
						id: header.header_id,
						value: header.value,
						disabled: true,
					});
				});
			}

			const arr = items.map((item) => {
				const evaluations = item.evaluations.map((evaluation) => {
					return {
						...evaluation,
						id: evaluation?.element_ambiental_id,
						name: evaluation?.element?.name,
					};
				});
				return {
					...item,
					currentId: item.id,
					evaluations,
				};
			});
			this.items = [...arr];
		},
		validateHeader() {
			let result = true;
			if (this.inspection?.headers && Array.isArray(this.inspection?.headers)) {
				this.inspection.headers.forEach((header) => {
					if (
						!header.value ||
						header.value === undefined ||
						header.value == ''
					) {
						result = false;
					}
				});
			}
			return result;
		},
		saveRegister(status = 'PENDING') {
			if (status == 'PENDING') {
				this.isLoadingSave = true;
			} else {
				this.isLoadingDraft = true;
			}
			try {
				const service_id = this.findValueInArray({
					items: this.inspection?.headers,
					prop: 'key',
					val: 'service',
					propFound: 'value',
				});
				let data = {
					register: {
						inspection_id: this.inspection.id,
						format: this.inspection.format,
						service_id,
						status,
						date: this.findValueInArray({
							items: this.inspection?.headers,
							prop: 'key',
							val: 'date',
							propFound: 'value',
						}),
						manager_id: this.findValueInArray({
							items: this.inspection?.headers,
							prop: 'key',
							val: 'manager',
							propFound: 'value',
						}),
						management_id: this.findValueInArray({
							items: this.costCenters,
							prop: 'id',
							val: service_id,
							propFound: 'management_id',
						}),
						company_id: parseInt(localStorage.getItem('company_id')),
					},
					headers: [],
					ambientalKits: [],
				};
				data.headers = this.inspection.headers.map((header) => {
					return {
						header_id: header.id,
						value: (header.value || '').toString() || null,
					};
				});
				data.ambientalKits = this.items.map((item) => {
					const evaluations = item.evaluations.map((evaluation) => {
						return {
							quantity: 1,
							value: evaluation.value,
							observations: evaluation?.observations || null,
							element_ambiental_id: parseInt(evaluation?.id),
						};
					});
					return {
						ubication: item.ubication,
						capacity: item.capacity,
						signage: item.signage,
						observations: null,
						evaluations,
					};
				});
				this.sendRegister(data);
			} catch (error) {}
		},
		sendRegister(data) {
			this.validationErrors = [];
			this.create({
				success: () => {
					this.isLoadingSave = false;
					this.isLoadingDraft = false;
					this.$router.push({
						name: 'InspectionsRegisterList',
						params: { id: this.inspection.format },
					});
					$EventBus.$emit(
						'showSnack',
						'success',
						'Se ha registrado satisfactoriamente.'
					);
				},
				fail: (error) => {
					const { response } = error;
					this.isLoadingSave = false;
					this.isLoadingDraft = false;
					if (response && response?.data?.message) {
						this.validationErrors = Array.isArray(response?.data?.message)
							? response?.data?.message
							: [];
					} else {
						this.validationErrors = [
							'Algo salio mal, no se guardo la información.',
						];
					}
				},
				data,
			});
		},
		findValueInArray({ items = [], prop = null, val = null, propFound = null }) {
			let found;
			if (Array.isArray(items) && prop && val) {
				found = items.find((item) => item[prop] == val);
				if (found && propFound) {
					found = found[propFound] ? found[propFound] : null;
				}
			}
			return found || null;
		},
		isCompleteInspection() {
			const headers = this.inspection?.headers || [];
			const items = this.items || [];
			let incomplete = false;

			headers.forEach((header) => {
				if (!header?.value) {
					incomplete = true;
				}
			});

			if (items.length == 0) {
				incomplete = true;
			}
			return !incomplete;
		},
		isCompletedItems() {
			const items = this.items || [];
			return !!(Array.isArray(items) && items.length > 0);
		},
		/**
		 * new features
		 */

		openDialog() {
			this.currentItem = {
				ubication: null,
				capacity: null,
				signage: null,
				observations: null,
				hasObservation: false,
				evaluations: [...this.inspection?.elements],
				answers: [...this.inspection?.optionsEvaluation],
			};
			this.evaluationToolDialog = true;
		},
		addItem(item) {
			const index = this.items.findIndex(
				(element) => element?.currentId == item?.currentId
			);
			if (index >= 0) {
				Object.assign(this.items[index], item);
			} else {
				this.items.push({ ...item, currentId: new Date().getTime() });
			}
			this.currentItem = null;
		},
		editItem(data) {
			this.currentItem = {
				...data,
				answers: [...this.inspection?.optionsEvaluation],
			};
			this.evaluationToolDialog = true;
		},
		backPage() {
			if (this.$route.name == 'InspectionsRegisterRevision') {
				this.$router.push({
					name: 'InspectionsRegisterListRevision',
				});
			} else {
				this.$router.push({
					name: 'InspectionsRegisterList',
					params: {
						id: this.$route.params.inspectionId,
					},
				});
			}
		},
		approveInspection() {
			Swal.fire({
				title: '¿Está seguro que desea aprobar esta inspección?',
				text: 'Confirme acción',
				icon: 'question',
				reverseButtons: true,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'No, Cancelar',
				confirmButtonText: '¡Si, Aprobar!',
			}).then((result) => {
				if (result.value) {
					this.isLoadingSave = true;
					this.updateStatus({
						success: () => {
							this.isLoadingSave = false;
							this.backPage();
							$EventBus.$emit(
								'showSnack',
								'success',
								'Se ha registrado satisfactoriamente.'
							);
						},
						fail: (error) => {
							this.isLoadingSave = false;
							let message = error?.response?.data?.message;
							message = Array.isArray(message) ? message : [];
							this.validationErrors = message;
							$EventBus.$emit('showSnack', 'error', message.join(' '));
						},
						data: { format: this.inspection.format, id: this.register.id },
					});
				}
			});
		},
	},
	destroyed() {
		this.cleanInspection();
	},
	components: {
		FormHeader,
		EvaluationDialog,
		ValidationAlert,
		ElementTable,
	},
};
