import CbodyDatePicker from '@/components/inspections/inputs/CbodyDatePicker.vue';
//import { $EventBus } from '@/main';

export default {
	name: 'EvaluationTable',
	props: {
		value: {
			type: Array,
			required: true,
		},
		disabled: {
			type: Boolean,
			required: false,
		},
	},
	data: () => ({
		tool: null,
		headers: [
			
			{
				text: 'Ubicación',
				value: 'ubication',
				sortable: false,
				
				class: 'primary--text',
			},
			{
				text: 'Capacidad en Galones',
				value: 'capacity',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: '',
				value: 'status',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Acciones',
				value: 'actions',
				sortable: false,
				align: 'end',
				class: 'primary--text',
			},
		],
	}),
	computed: {
		items: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	watch: {},
	methods: {
		removeItem(id) {
			const index = this.items.findIndex((element) => element.currentId == id);
			if (index >= 0) this.items.splice(index, 1);
		},

		editItem(item) {
			this.$emit('editItem', JSON.parse(JSON.stringify(item)));
		},
	},
	components: {
		CbodyDatePicker,
	},
};
